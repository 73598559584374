<template v-slot:activator="{ on: on }">
  <v-btn
    v-if="buttonStyle == 'default'"
    :class="{
      primary: buttonType === 'default' || buttonType == 'primary',
      success: buttonType === 'success',
      warning: buttonType === 'warning',
      error: buttonType === 'error',
      'grey lighten-2': buttonType === 'grey',
    }"
    :x-small="buttonSize === 'x-small'"
    :small="buttonSize === 'small'"
    :large="buttonSize === 'large'"
    :x-large="buttonSize === 'x-large'"
    :disabled="disabled"
    :loading="loading"
    :block="block"
    v-bind="attrs"
    :to="to"
    tile
    elevation="0"
    @click="clicked($event)"
    v-on="on"
  >
    <slot></slot>
    <slot name="content"></slot>
  </v-btn>
  <v-btn
    v-else-if="buttonStyle == 'text'"
    :class="{
      'primary--text': buttonType === 'default',
      'primary--text': buttonType === 'primary',
      'success--text': buttonType === 'success',
      'warning--text': buttonType === 'warning',
      'error--text': buttonType === 'error',
      'grey--text': buttonType === 'grey',
    }"
    :x-small="buttonSize === 'x-small'"
    :small="buttonSize === 'small'"
    :large="buttonSize === 'large'"
    :x-large="buttonSize === 'x-large'"
    :disabled="disabled"
    :loading="loading"
    :block="block"
    text
    v-bind="attrs"
    :to="to"
    tile
    elevation="0"
    @click="clicked"
    v-on="on"
  >
    <slot></slot>
    <slot name="content"></slot>
  </v-btn>
  <v-btn
    v-else-if="buttonStyle == 'outlined'"
    :class="{
      'primary--text': buttonType === 'default',
      'primary--text': buttonType === 'primary',
      'success--text': buttonType === 'success',
      'warning--text': buttonType === 'warning',
      'error--text': buttonType === 'error',
      'grey--text': buttonType === 'grey',
    }"
    :x-small="buttonSize === 'x-small'"
    :small="buttonSize === 'small'"
    :large="buttonSize === 'large'"
    :x-large="buttonSize === 'x-large'"
    :disabled="disabled"
    :loading="loading"
    :block="block"
    outlined
    v-bind="attrs"
    :to="to"
    tile
    elevation="0"
    @click="clicked"
    v-on="on"
  >
    <slot></slot>
    <slot name="content"></slot>
  </v-btn>
  <v-btn
    v-else-if="buttonStyle === 'icon'"
    :class="{
      'primary--text': buttonType === 'default',
      'primary--text': buttonType === 'primary',
      'success--text': buttonType === 'success',
      'warning--text': buttonType === 'warning',
      'error--text': buttonType === 'error',
      'grey--text': buttonType === 'grey',
    }"
    :x-small="buttonSize === 'x-small'"
    :small="buttonSize === 'small'"
    :large="buttonSize === 'large'"
    :x-large="buttonSize === 'x-large'"
    :disabled="disabled"
    :loading="loading"
    :block="block"
    icon
    v-bind="attrs"
    :to="to"
    elevation="0"
    @click="clicked"
    v-on="on"
  >
    <slot></slot><slot name="content"></slot
  ></v-btn>
  <v-btn
    v-else-if="buttonStyle === 'iconOutlined'"
    :class="{
      'primary--text': buttonType === 'default',
      'primary--text': buttonType === 'primary',
      'success--text': buttonType === 'success',
      'warning--text': buttonType === 'warning',
      'error--text': buttonType === 'error',
      'grey--text': buttonType === 'grey',
    }"
    :x-small="buttonSize === 'x-small'"
    :small="buttonSize === 'small'"
    :large="buttonSize === 'large'"
    :x-large="buttonSize === 'x-large'"
    :disabled="disabled"
    :loading="loading"
    :block="block"
    icon
    outlined
    v-bind="attrs"
    :to="to"
    elevation="0"
    @click="clicked"
    v-on="on"
  >
    <slot></slot><slot name="content"></slot
  ></v-btn>
</template>
<script>
export default {
  props: {
    attrs: {
      type: Object,
      default: () => ({}),
    },
    on: {
      type: Object,
      default: () => ({}),
    },
    buttonType: {
      type: String,
      default: 'default',
    },
    buttonStyle: {
      type: String,
      default: 'default',
    },
    buttonSize: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object],
      default: undefined,
    },
    loading: { type: Boolean, default: false },
  },
  methods: {
    clicked(event) {
      this.$emit('click', event)
    },
  },
}
</script>
