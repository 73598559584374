import axios from 'axios'

export function getGenders() {
  return axios.get('/v1/util/genders')
}

export function predictGenderBasedOnFirstName(firstName) {
  return axios.get('/v1/util/autofill/gender', {
    params: { name: firstName },
  })
}
