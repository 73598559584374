import axios from 'axios'
import { decodeJwt } from 'jose'
import { DateTime } from 'luxon'
import store from '@/store/index.js'
import router from '@/router/index.js'

const REFRESH_BEFORE_BEARER_EXPIRES_SECONDS = 59

export function getBearerFromLocalStorage() {
  try {
    return JSON.parse(localStorage.getItem('auth')).bearer
  } catch (e) {
    return null
  }
}

export function getRefreshTokenFromLocalStorage() {
  try {
    return JSON.parse(localStorage.getItem('auth')).refreshToken
  } catch (e) {
    return null
  }
}

export function loginWithUsernameAndPassword(credentials) {
  return axios.post('/v1/login', credentials)
}

export async function logout(refreshToken) {
  const response = await axios.post('/v1/logout', { refreshToken })

  return response.data
}

export async function renewBearerWithRefreshToken(refreshToken) {
  await axios
    .post('/v1/refresh', {
      refreshToken: refreshToken,
    })
    .then((res) => {
      store.dispatch('setAuthData', res.data)
      console.debug('File: auth.js, Msg: bearer renewed')
      postAuthActions()
    })
    .catch(() => {
      console.debug('File: auth.js, Msg: FAILED to renew bearer')
      store.dispatch('reAuth')
    })
}

export async function renewBearerWithRefreshTokenAndNavigateToPath(
  refreshToken,
  path,
  query
) {
  await renewBearerWithRefreshToken(refreshToken)

  delete query.refreshToken
  router.push({ path, query })
}

export async function writeAuthToLocalStorage(auth) {
  localStorage.setItem('auth', JSON.stringify(auth))
  try {
    let bearerExpiringTime = DateTime.fromSeconds(decodeJwt(auth.bearer).exp)
    let jwtRenewInSeconds = Math.floor(
      bearerExpiringTime.diff(DateTime.now(), ['seconds']).seconds -
        REFRESH_BEFORE_BEARER_EXPIRES_SECONDS
    )
    // setTimeout in Milliseconds
    setTimeout(() => {
      renewBearerWithRefreshToken(getRefreshTokenFromLocalStorage())
    }, jwtRenewInSeconds * 1000)
  } catch (err) {
    console.debug(err)
    console.debug('authService.js: bearer not set')
  }
}

export function logoutHandler() {
  console.log('clearing Intervals now')
  const highestIntervalId = setInterval(';')
  for (let i = 0; i <= highestIntervalId; i++) {
    clearInterval(i)
  }
}

export function postAuthActions() {
  // General Initialization
  store.dispatch('loadGenders')
}
