export default {
  state: {
    showInvoices: true,
  },
  mutations: {
    SET_SHOW_INVOICES(state, showInvoices) {
      state.showInvoices = showInvoices
    },
  },
  actions: {
    setShowInvoices({ commit }, showInvoices) {
      commit('SET_SHOW_INVOICES', showInvoices)
    },
  },
}
