import axios from 'axios'

export async function getDetailedArticleSaleChart(artNr, from, to, pointIndex) {
  const response = await axios.get(
    `/v1/statistics/article/${artNr}/totalrevenue?from=${from}&to=${to}&dPoint=${pointIndex}`
  )

  return response.data
}

export async function getArticleSaleChart(artNr, from, to) {
  const response = await axios.get(
    `/v1/statistics/article/${artNr}/revenue?from=${from}&to=${to}`
  )

  return response.data
}

export async function getArticleSaleDataPerAddress(
  artNr,
  from,
  to,
  pointIndex
) {
  const response = await axios.get(
    `/v1/statistics/article/${artNr}/SaleDataPerAddress?from=${from}&to=${to}&dPoint=${pointIndex}`
  )

  return response.data
}

export async function getArticleSaleDataSM(artNr, from, to, pointIndex) {
  const response = await axios.get(
    `/v1/statistics/article/${artNr}/SaleDataSM?from=${from}&to=${to}&dPoint=${pointIndex}`
  )

  return response.data
}

export async function getArticleSaleDataSMPerAddress(
  artNr,
  SMcharacteristic,
  from,
  to,
  pointIndex
) {
  const response = await axios.get(
    `/v1/statistics/article/${artNr}/SaleDataSMPerAddress/${SMcharacteristic}?from=${from}&to=${to}&dPoint=${pointIndex}`
  )

  return response.data
}
