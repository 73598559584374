import { render, staticRenderFns } from "./LBAButton.vue?vue&type=template&id=60e94a75&v-slot%3Aactivator=%7B%20on%3A%20on%20%7D"
import script from "./LBAButton.vue?vue&type=script&lang=js"
export * from "./LBAButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports