import axios from 'axios'

export async function getArticle(id) {
  const response = await axios.get(`/v1/articles/${id}`)

  return response.data
}

export async function getArticleImages(id) {
  const response = await axios.get(`/v1/articles/${id}/image`)

  return response.data
}

export async function getArticleCheckboxes(id) {
  const response = await axios.get(`/v1/articles/${id}/checkboxes`)

  return response.data
}

export async function getArticleInventory(id) {
  const response = await axios.get(`/v1/articles/${id}/inventory`, {
    params: { artNr: id },
  })

  return response.data
}

export async function getArticleCharacteristics(id) {
  const response = await axios.get(`/v1/articles/${id}/characteristics`, {
    params: { artNr: id },
  })

  return response.data
}

export async function getArticleSalePrices(id) {
  const response = await axios.get(`/v1/articles/${id}/prices/sales`)

  return response.data
}

export async function getArticlePurchasePrices(id) {
  try {
    const response = await axios.get(`/v1/articles/${id}/prices/purchases`, {
      params: { artNr: id },
    })

    return response.data
  } catch (err) {
    if (err.request.status === 404) return []

    throw err
  }
}

export async function getArticleTransport(id) {
  try {
    const response = await axios.get(`/v1/articles/${id}/movements`)

    return response.data
  } catch (err) {
    if (err.request.status === 404) return []

    throw err
  }
}

export async function getArticleCommissionedOrdered(id) {
  try {
    const response = await axios.get(`/v1/articles/${id}/commissionedOrdered`, {
      params: { artNr: id },
    })

    return response.data
  } catch (err) {
    if (err.request.status === 404) return []

    throw err
  }
}

export async function getArticleStock(id) {
  try {
    const response = await axios.get(`/v1/articles/${id}/stock`, {
      params: { artNr: id },
    })

    return response.data
  } catch (err) {
    if (err.request.status === 404) return []
    throw err
  }
}

export async function getOpenArticleOffers(id) {
  try {
    const response = await axios.get(`/v1/articles/${id}/openOffers`, {
      params: { artNr: id },
    })

    return response.data
  } catch (err) {
    if (err.request.status === 404) return []

    throw err
  }
}
