<template>
  <LBADialog
    ref="dialog"
    :max-width="330"
    :centered-content="true"
    :persistent="true"
  >
    <template #title>{{ dialogText }}</template>
    <template #activator="{ on, attrs }"
      ><slot name="activator"
        ><LBAButton v-bind="attrs" :buttonStyle="'default'" v-on="on"
          ><v-icon
            v-if="iconPosition == 'left' && iconPosition != 'none'"
            class="mr-2"
            >mdi-calendar</v-icon
          >{{ buttonText
          }}<v-icon
            v-if="iconPosition == 'right' && iconPosition != 'none'"
            class="ml-2"
            >mdi-calendar</v-icon
          ></LBAButton
        ></slot
      ></template
    >

    <template #content>
      <v-date-picker
        v-model="selectedDate"
        no-title
        scrollable
        first-day-of-week="1"
        color="primary"
        :min="minimumDate"
        :max="maximumDate"
        @change="emitChange()"
      >
      </v-date-picker>
    </template>
    <template #success>
      <!--Button welcher Datepicker schließt und das ausgewählte Datum speichert-->
      <LBAButton @click="datePicked(), close()">OK</LBAButton>
    </template>
  </LBADialog>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Date],
      default: null,
    },
    buttonText: {
      type: String,
      default: 'Datum wählen',
    },
    dialogText: {
      type: String,
      default: '',
    },
    iconPosition: {
      type: String,
      default: 'none',
    },
    minimumDate: {
      type: [String, Date],
      default: null,
    },
    maximumDate: {
      type: [String, Date],
      default: null,
    },
  },
  data() {
    return {
      selectedDate: '',
      datePicker: false,
      date: '',
    }
  },
  watch: {
    value(newValue) {
      this.selectedDate = newValue // Update selectedDate when the value prop changes
    },
  },
  mounted() {
    // Initialize selectedDate when the component is mounted
    this.selectedDate = this.value
  },
  methods: {
    close() {
      this.$refs.dialog.closeDialog()
    },
    datePicked() {
      this.$emit('datePicked', this.selectedDate)
    },
    emitChange() {
      this.$emit('input', this.selectedDate)
    },
  },
}
</script>
