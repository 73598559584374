import {
  getTaskPreviews,
  getTaskPreviewsForUser,
  getTaskForLfdnr,
  getAssignableUsers,
  getAssignableWorkGroups,
  getTaskTitles,
  getTaskStates,
} from '@/services/task/taskService.js'

export default {
  state: {
    taskPreviews: [],
    taskPreviewsUser: [],
    taskPreviewsUser_open: [],
    taskPreviewsUser_done: [],
    taskPreviews_open: [],
    taskPreviews_done: [],
    taskDetailed: {},
    assignableUsers: [],
    assignableWorkGroups: [],
    titles: [],
    taskStates: [],
  },
  mutations: {
    SET_TASK_PREVIEWS(state, taskPreviews) {
      state.taskPreviews_done = []
      state.taskPreviews_open = []
      state.taskPreviews = taskPreviews
      taskPreviews.forEach((element) => {
        if (element.status.done) state.taskPreviews_done.push(element)
        else state.taskPreviews_open.push(element)
      })
    },
    SET_TASK_PREVIEWS_USER(state, taskPreviews) {
      state.taskPreviewsUser_done = []
      state.taskPreviewsUser_open = []
      state.taskPreviewsUser = taskPreviews
      taskPreviews.forEach((element) => {
        if (element.status.done) state.taskPreviewsUser_done.push(element)
        else state.taskPreviewsUser_open.push(element)
      })
    },
    SET_TASK_DETAILED(state, taskDetailed) {
      state.taskDetailed = taskDetailed
    },
    SET_ASSIGNABLE_USERS(state, assignableUser) {
      state.assignableUsers = assignableUser
    },
    SET_ASSIGNABLE_WORK_GROUPS(state, assignableWorkGroups) {
      state.assignableWorkGroups = assignableWorkGroups
    },
    SET_TITLES(state, titles) {
      state.titles = titles
    },
    SET_STATES(state, states) {
      state.taskStates = states
    },
  },
  actions: {
    async loadTaskPreviews({ commit }) {
      commit('SET_TASK_PREVIEWS', await getTaskPreviews())
    },
    async loadTaskPreviewsForUser({ commit }) {
      commit('SET_TASK_PREVIEWS_USER', await getTaskPreviewsForUser())
    },
    async loadTask({ commit }, lfdnr) {
      commit('SET_TASK_DETAILED', await getTaskForLfdnr(lfdnr))
    },
    async loadAssignableUsers({ commit }) {
      commit('SET_ASSIGNABLE_USERS', await getAssignableUsers())
    },
    async loadAssignableWorkGroups({ commit }) {
      commit('SET_ASSIGNABLE_WORK_GROUPS', await getAssignableWorkGroups())
    },
    async loadTitles({ commit }) {
      commit('SET_TITLES', await getTaskTitles())
    },
    async loadTaskStates({ commit }) {
      commit('SET_STATES', await getTaskStates())
    },
  },
}
