import router from '@/router/index.js'
import {
  getBearerFromLocalStorage,
  getRefreshTokenFromLocalStorage,
  writeAuthToLocalStorage,
  loginWithUsernameAndPassword,
  postAuthActions,
  logoutHandler,
  logout,
} from '@/services/core/authService.js'

export default {
  state: {
    bearer: getBearerFromLocalStorage(),
    refreshToken: getRefreshTokenFromLocalStorage(),
  },
  mutations: {
    SET_AUTH_DATA(state, authData) {
      state.bearer = authData.bearer
      state.refreshToken = authData.refreshToken

      writeAuthToLocalStorage(authData)
    },
    CLEAR_AUTH_DATA(state) {
      state.bearer = null
      state.refreshToken = null
      localStorage.removeItem('auth')
    },
  },
  actions: {
    async loadAuthFromLocalStorage({ commit }) {
      let auth = await JSON.parse(localStorage.getItem('auth'))

      if (auth != null) {
        commit('SET_AUTH_DATA', auth)
      }
    },
    async setAuthData({ commit }, data) {
      commit('SET_AUTH_DATA', data)
    },
    login({ dispatch }, credentials) {
      return loginWithUsernameAndPassword(credentials).then((response) => {
        dispatch('setAuthData', response.data)
        if (!router.currentRoute.query.redirectTo) {
          router.currentRoute.query.redirectTo = '/'
        }
        router.push(router.currentRoute.query.redirectTo)

        postAuthActions()
      })
    },
    reAuth({ commit }) {
      commit('CLEAR_AUTH_DATA')
      router.push({ name: 'Login' })
    },
    async logout({ commit, state }) {
      logoutHandler()
      await logout(state.refreshToken)
      router.push('/login?logout=true')
      commit('CLEAR_AUTH_DATA')
      router.go()
    },
  },
  getters: {
    loggedIn(state) {
      return !!state.bearer
    },
  },
}
