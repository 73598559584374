var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.buttonStyle == 'default')?_c('v-btn',_vm._g(_vm._b({class:{
    primary: _vm.buttonType === 'default' || _vm.buttonType == 'primary',
    success: _vm.buttonType === 'success',
    warning: _vm.buttonType === 'warning',
    error: _vm.buttonType === 'error',
    'grey lighten-2': _vm.buttonType === 'grey',
  },attrs:{"x-small":_vm.buttonSize === 'x-small',"small":_vm.buttonSize === 'small',"large":_vm.buttonSize === 'large',"x-large":_vm.buttonSize === 'x-large',"disabled":_vm.disabled,"loading":_vm.loading,"block":_vm.block,"to":_vm.to,"tile":"","elevation":"0"},on:{"click":function($event){return _vm.clicked($event)}}},'v-btn',_vm.attrs,false),_vm.on),[_vm._t("default"),_vm._t("content")],2):(_vm.buttonStyle == 'text')?_c('v-btn',_vm._g(_vm._b({class:{
    'primary--text': _vm.buttonType === 'default',
    'primary--text': _vm.buttonType === 'primary',
    'success--text': _vm.buttonType === 'success',
    'warning--text': _vm.buttonType === 'warning',
    'error--text': _vm.buttonType === 'error',
    'grey--text': _vm.buttonType === 'grey',
  },attrs:{"x-small":_vm.buttonSize === 'x-small',"small":_vm.buttonSize === 'small',"large":_vm.buttonSize === 'large',"x-large":_vm.buttonSize === 'x-large',"disabled":_vm.disabled,"loading":_vm.loading,"block":_vm.block,"text":"","to":_vm.to,"tile":"","elevation":"0"},on:{"click":_vm.clicked}},'v-btn',_vm.attrs,false),_vm.on),[_vm._t("default"),_vm._t("content")],2):(_vm.buttonStyle == 'outlined')?_c('v-btn',_vm._g(_vm._b({class:{
    'primary--text': _vm.buttonType === 'default',
    'primary--text': _vm.buttonType === 'primary',
    'success--text': _vm.buttonType === 'success',
    'warning--text': _vm.buttonType === 'warning',
    'error--text': _vm.buttonType === 'error',
    'grey--text': _vm.buttonType === 'grey',
  },attrs:{"x-small":_vm.buttonSize === 'x-small',"small":_vm.buttonSize === 'small',"large":_vm.buttonSize === 'large',"x-large":_vm.buttonSize === 'x-large',"disabled":_vm.disabled,"loading":_vm.loading,"block":_vm.block,"outlined":"","to":_vm.to,"tile":"","elevation":"0"},on:{"click":_vm.clicked}},'v-btn',_vm.attrs,false),_vm.on),[_vm._t("default"),_vm._t("content")],2):(_vm.buttonStyle === 'icon')?_c('v-btn',_vm._g(_vm._b({class:{
    'primary--text': _vm.buttonType === 'default',
    'primary--text': _vm.buttonType === 'primary',
    'success--text': _vm.buttonType === 'success',
    'warning--text': _vm.buttonType === 'warning',
    'error--text': _vm.buttonType === 'error',
    'grey--text': _vm.buttonType === 'grey',
  },attrs:{"x-small":_vm.buttonSize === 'x-small',"small":_vm.buttonSize === 'small',"large":_vm.buttonSize === 'large',"x-large":_vm.buttonSize === 'x-large',"disabled":_vm.disabled,"loading":_vm.loading,"block":_vm.block,"icon":"","to":_vm.to,"elevation":"0"},on:{"click":_vm.clicked}},'v-btn',_vm.attrs,false),_vm.on),[_vm._t("default"),_vm._t("content")],2):(_vm.buttonStyle === 'iconOutlined')?_c('v-btn',_vm._g(_vm._b({class:{
    'primary--text': _vm.buttonType === 'default',
    'primary--text': _vm.buttonType === 'primary',
    'success--text': _vm.buttonType === 'success',
    'warning--text': _vm.buttonType === 'warning',
    'error--text': _vm.buttonType === 'error',
    'grey--text': _vm.buttonType === 'grey',
  },attrs:{"x-small":_vm.buttonSize === 'x-small',"small":_vm.buttonSize === 'small',"large":_vm.buttonSize === 'large',"x-large":_vm.buttonSize === 'x-large',"disabled":_vm.disabled,"loading":_vm.loading,"block":_vm.block,"icon":"","outlined":"","to":_vm.to,"elevation":"0"},on:{"click":_vm.clicked}},'v-btn',_vm.attrs,false),_vm.on),[_vm._t("default"),_vm._t("content")],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }