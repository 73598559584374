import axios from 'axios'

//Gibt die Parameter für Filter mit und ladet dann die gefilterten Artikel
export async function filterArticle(
  //{ commit },
  akey,
  aartNr2,
  aarticleDescription,
  aarticleType,
  aarticleCategory,
  aarticleGroup,
  controller
) {
  const response = await axios.post(
    '/v1/articles/search/filtered',
    {
      key: akey,
      artNr2: aartNr2,
      articleDescription: aarticleDescription,
      articleType: aarticleType,
      articleCategory: aarticleCategory,
      articleGroup: aarticleGroup,
    },
    {
      signal: controller.signal,
    }
  )

  return response.data
}

export async function getArticleTypes() {
  const response = await axios.get('/v1/articles/types')

  return response.data
}

export async function getFilteredArticleCategories(articleType) {
  const response = await axios.post(
    '/v1/articlesFilteredDependencies/dependenciesCategories',
    articleType
  )

  return response.data
}

export async function getFilteredArticleGroups(articleType) {
  const response = await axios.post(
    '/v1/articlesFilteredDependencies/dependenciesGroups',
    articleType
  )

  return response.data
}

export async function getArticleCategories() {
  const response = await axios.get('/v1/articles/categories')

  return response.data
}

export async function getArticleGroups() {
  const response = await axios.get('/v1/articles/groups')

  return response.data
}
