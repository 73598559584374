<template>
  <v-chip :small="small" :color="color" outlined class="primary--text">
    <v-icon :small="small" left>mdi-source-branch</v-icon> {{ VERSION }}
  </v-chip>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    VERSION() {
      return `${process.env.VUE_APP_VERSION}`
    },
  },
}
</script>
