import { getVisibleArticleTabs } from '@/services/article/permissionService.js'

export default {
  state: {
    //Variablen
    visibleTabs: [],
  },

  mutations: {
    //Setter
    SET_VISIBLETABS(state, visibleTabs) {
      state.visibleTabs = visibleTabs
    },
  },

  actions: {
    async loadVisibleTabs({ commit }) {
      commit('SET_VISIBLETABS', await getVisibleArticleTabs())
    },
  },

  getters: {
    //Getter
    visibleTabs(state) {
      return state.visibleTabs
    },
  },
}
