import { getCompany } from '@/services/core/appService.js'

export default {
  state: {
    company: {
      name: '',
      street: '',
      city: '',
      country: '',
      postcode: 0,
      tel: '',
      email: '',
      logo: '',
    },
  },
  mutations: {
    SET_COMPANY_DATA(state, companyData) {
      state.company = companyData
    },
  },
  actions: {
    loadCompanyData({ commit }) {
      return getCompany().then((company) => {
        commit('SET_COMPANY_DATA', company)
      })
    },
  },
  getters: {
    company(state) {
      return state.company
    },
  },
}
