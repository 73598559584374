<template>
  <v-app>
    <!-- Snackbar | Neue PWA-Version verfügbar -->
    <v-snackbar
      bottom
      right
      :value="updateExists"
      :timeout="-1"
      color="warning"
    >
      <span class="pa-3">Eine neue Version ist verfügbar.</span>
      <v-btn class="ml-4" outlined @click="refreshApp">
        Jetzt Aktualisieren
      </v-btn>
    </v-snackbar>

    <Header :drawer="drawer" @drawerChange="drawerChange" />
    <NavBar v-if="loggedIn" :drawer="drawer" @drawerChange="drawerChange" />
    <v-main class="white">
      <router-view></router-view>
    </v-main>
    <Footer v-if="!isMobile()"></Footer>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import swUpdate from '@/mixins/swUpdate.js'

import Header from '@/components/core/Header.vue'
import NavBar from '@/components/core/NavBar.vue'
import Footer from '@/components/core/Footer.vue'
//import BreadCrumbComponent from '@/components/core/BreadCrumbComponent.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    NavBar,
    //BreadCrumbComponent
  },
  mixins: [swUpdate],
  data() {
    return {
      settings: {
        default: null,
      },
      drawer: false,
      isOnline: navigator.isOnline,
      lastIsOnlineState: true,
    }
  },
  computed: {
    ...mapGetters(['loggedIn']),
  },
  watch: {
    isOnline(v) {
      if (!v) {
        this.$toast.error(
          'Aktivieren Sie Ihre Internetverbindung um LBA-WEB verwenden zu können!',
          {
            timeout: false,
            closeButton: false,
            draggable: false,
            closeOnClick: false,
            id: 'isOffline',
          }
        )
        this.lastIsOnlineState = false
      }
      if (v && !this.lastIsOnlineState) {
        this.lastIsOnlineState = true
        this.$toast.dismiss('isOffline')
        this.$toast.success('Internetverbindung hergestellt!')
      }
    },
  },
  mounted() {
    // falls User die Seite refreshed und ein Token gespeichert ist, wird dieser Token für zukünftige requests benutzt
    this.$store.dispatch('loadAuthFromLocalStorage')
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true
      } else {
        return false
      }
    },
    drawerChange(newVal) {
      this.drawer = newVal
    },
    updateOnlineStatus(e) {
      const { type } = e
      this.isOnline = type === 'online'
    },
  },
}
</script>

<style>
.centerElement {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #c8c6d2 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #c8c6d2;
  border-radius: 10px;
  border: 3px solid #ffffff;
}
.scrollable-content {
  height: 100%;
}
.vb > .vb-dragger {
  z-index: 5;
  width: 12px;
  right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0, 0, 0, 0);
  transform: rotate3d(0, 0, 0, 0);
  -webkit-transition:
    background-color 100ms ease-out,
    margin 100ms ease-out,
    height 100ms ease-out;
  transition:
    background-color 100ms ease-out,
    margin 100ms ease-out,
    height 100ms ease-out;
  background-color: rgba(48, 121, 244, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 20px;
  height: calc(100% - 10px);
  display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.3);
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
}

.border-left-primary-3 {
  border-left: 3px solid var(--v-primary-base) !important;
  border-radius: 0px !important;
}

.border-left-primary-5 {
  border-left: 3px solid var(--v-primary-base) !important;
  border-radius: 0px !important;
}

.border-bottom-primary-5 {
  border-bottom: 5px solid var(--v-primary-base) !important;
  border-radius: 0px !important;
}
.border-bottom-primary-4 {
  border-bottom: 4px solid var(--v-primary-base) !important;
  border-radius: 0px !important;
}
.border-bottom-primary-3 {
  border-bottom: 3px solid var(--v-primary-base) !important;
  border-radius: 0px !important;
}
</style>
