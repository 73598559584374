export default {
  state: {
    filterActivated: false,
    filter: {
      name: null,
      street: null,
      postalCode: null,
      city: null,
      addressType: null,
      customerGroup: null,
      field: null,
    },
  },
  mutations: {
    TOGGLE_FILTER_ACTIVATED(state) {
      state.filterActivated = !state.filterActivated
    },
    UNSET_FILTER_ACTIVE(state) {
      state.filterActivated = false
    },
    SET_FILTER(state, filter) {
      state.filter = {
        name: filter.name,
        street: filter.street,
        postalCode: filter.postalCode,
        city: filter.city,
        addressType: filter.addressType,
        customerGroup: filter.customerGroup,
        field: filter.field,
      }
    },
    RESET_FILTER(state) {
      state.filter.name = null
      state.filter.street = null
      state.filter.postalCode = null
      state.filter.city = null
      state.filter.addressType = null
      state.filter.customerGroup = null
      state.filter.field = null
    },
  },
  actions: {
    toggleFilterActivated({ commit }) {
      commit('TOGGLE_FILTER_ACTIVATED')
    },
    unsetFilterActive({ commit }) {
      commit('UNSET_FILTER_ACTIVE')
    },
    setFilter({ commit }, filter) {
      if (filter) {
        commit('SET_FILTER', filter)
      }
    },
    resetFilter({ commit }) {
      commit('RESET_FILTER')
    },
  },
  getters: {
    isFilterActivated(state) {
      return state.filterActivated === true
    },
    getFilter(state) {
      return state.filter
    },
  },
}
