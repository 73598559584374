import axios from 'axios'

export async function getCrmFunctions() {
  const response = await axios.get('/v1/crm/settings/functions')

  return response.data
}

export async function getCrmDepartments() {
  const response = await axios.get('/v1/crm/settings/departments')

  return response.data
}
