<template>
  <LBADialog
    ref="dialog"
    :max-width="330"
    :centered-content="true"
    :persistent="true"
  >
    <template #title>{{ dialogText }}</template>
    <template #activator="{ on, attrs }"
      ><slot name="activator"
        ><LBAButton v-bind="attrs" :buttonStyle="'default'" v-on="on"
          ><v-icon
            v-if="iconPosition == 'left' && iconPosition != 'none'"
            class="mr-2"
            >mdi-calendar-clock</v-icon
          >{{ buttonText
          }}<v-icon
            v-if="iconPosition == 'right' && iconPosition != 'none'"
            class="ml-2"
            >mdi-calendar-clock</v-icon
          ></LBAButton
        ></slot
      ></template
    >

    <template #content>
      <v-tabs v-model="tab" fixed-tabs icons-and-text>
        <v-tab key="date" default
          >{{ formatDate(dateTime.date) }}<v-icon>mdi-calendar-range</v-icon>
        </v-tab>
        <v-tab key="time" :disabled="allDay"
          >{{ dateTime.time }} <v-icon>mdi-clock</v-icon></v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item key="date">
          <v-date-picker
            v-model="dateTime.date"
            no-title
            scrollable
            first-day-of-week="1"
            color="primary"
            class="mt-3"
            :min="minimumDate"
            :max="maximumDate"
            @change="goToTime(), emitChange()"
          >
          </v-date-picker
        ></v-tab-item>
        <v-tab-item key="time">
          <v-row align="center" justify="center">
            <v-col>
              <v-text-field
                v-model="dateTime.time"
                maxlength="5"
                type="time"
                step="300"
                style="width: 55px"
                class="text-justify text-center"
                @click="switchToHourView"
                @change="roundToNearestFiveMinutes('up')"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-time-picker
            v-model="dateTime.time"
            :allowed-minutes="allowedStep"
            :active-picker.sync="activePicker"
            :max="maximumTime"
            :min="minimumTime"
            format="24hr"
            view="minute"
            no-title
            color="primary"
            @click:minute="switchToHourView"
            @change="emitChange()"
          ></v-time-picker>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template #success>
      <LBAButton @click="save(), close()">OK</LBAButton>
    </template>
  </LBADialog>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {
          time: '',
          date: '',
        }
      },
    },
    buttonText: {
      type: String,
      default: 'Datum und Uhrzeit wählen',
    },
    dialogText: {
      type: String,
      default: '',
    },
    allDay: {
      type: Boolean,
      default: false,
    },
    iconPosition: {
      type: String,
      default: 'none',
    },
    minimumDate: {
      type: [String, Date],
      default: null,
    },
    maximumDate: {
      type: [String, Date],
      default: null,
    },
    minimumTime: {
      type: String,
      default: '00:00',
    },
    maximumTime: {
      type: String,
      default: '23:59',
    },
  },
  data() {
    return {
      dateTime: {
        time: '',
        date: '',
      },
      tab: 0,
      hours: '',
      minutes: '',
      activePicker: '',
      allowedStep: (m) => m % 5 === 0,
    }
  },
  mounted() {
    // Initialize selectedDate when the component is mounted
    this.dateTime = this.value

    if (this.allDay) {
      this.dateTime.time = '--:--'
      this.tab = 0
    }
  },
  methods: {
    switchToHourView() {
      this.activePicker = 'HOUR'
    },
    save() {
      if (this.allDay) {
        this.$emit('datePicked', this.dateTime)
      } else {
        this.$emit('datePicked', this.dateTime)
      }
    },
    close() {
      this.$refs.dialog.closeDialog()
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    goToTime() {
      if (!this.allDay) this.tab = 1
    },
    updateTimeStr() {
      this.time = this.hours + ':' + this.minutes
    },
    roundToNearestFiveMinutes(roundingMode = 'up') {
      let timeString = this.dateTime.time

      const [hours, minutes] = timeString.split(':').map(Number)
      const remainder = minutes % 5

      // If remainder is 0, the time is already a multiple of 5, so return it as is
      if (remainder === 0) {
        return timeString
      }

      // Calculate the minutes to add or subtract to reach the nearest multiple of 5
      let minutesAdjustment = 0
      if (roundingMode === 'up') {
        minutesAdjustment = 5 - remainder
      } else if (roundingMode === 'down') {
        minutesAdjustment = -remainder
      }

      // Calculate the rounded time
      const roundedMinutes = (minutes + minutesAdjustment + 60) % 60
      const roundedHours =
        (hours + Math.floor((minutes + minutesAdjustment) / 60) + 24) % 24

      // Pad the hours and minutes with leading zeros if necessary
      const roundedHoursString = String(roundedHours).padStart(2, '0')
      const roundedMinutesString = String(roundedMinutes).padStart(2, '0')

      // Return the rounded time in HH:mm format
      this.dateTime.time = `${roundedHoursString}:${roundedMinutesString}`
    },
    emitChange() {
      this.$emit('input', this.dateTime)
    },
  },
}
</script>
