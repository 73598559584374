<template>
  <div>
    <LBADialog
      v-model="error.dialog"
      :persistent="true"
      dialogType="error"
      :centeredContent="true"
      @close="error.dialog = false"
    >
      <template #title>Ein Fehler ist aufgetreten</template>
      <template #content>
        <p>{{ error.message }}</p>
      </template>
      <template #success>
        <LBAButton @click="(error.dialog = false), $emit('scanned', '')">
          Schließen
        </LBAButton>
      </template>
    </LBADialog>
    <qrcode-stream
      :camera="camera"
      :formats="codeFormats"
      @decode="onDecode"
      @init="onInit"
    >
      <v-overlay v-if="loading || paused" :opacity="0.5" absolute>
        <p class="font-weight-bold text-center text-h5">
          Kamera wird geladen...
        </p>
      </v-overlay>
    </qrcode-stream>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  components: {
    QrcodeStream,
  },
  props: {
    paused: {
      type: Boolean,
      required: true,
    },
    codeFormats: {
      type: Array,
      required: false,
      default: () => ['code_128'],
    },
    showErrorMessage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      error: {
        message: '',
        dialog: false,
      },
      loading: false,
      showScanConfirmation: false,
    }
  },
  computed: {
    camera() {
      return this.paused ? 'off' : 'auto'
    },
  },

  methods: {
    async onDecode(result) {
      this.$emit('scanned', result)
    },
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

        ctx.strokeStyle = 'red'

        ctx.beginPath()
        ctx.moveTo(firstPoint.x, firstPoint.y)
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y)
        }
        ctx.lineTo(firstPoint.x, firstPoint.y)
        ctx.closePath()
        ctx.stroke()
      }
    },
    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height },
        } = detectedCode

        ctx.lineWidth = 2
        ctx.strokeStyle = 'red'
        ctx.strokeRect(x, y, width, height)
      }
    },

    async onInit(promise) {
      this.loading = true

      try {
        await promise
      } catch (error) {
        this.error.dialog = true

        if (error.name === 'NotAllowedError') {
          this.error.message =
            'Zugriff auf die Kamera verweigert. Bitte erteilen Sie die Kamera-Berechtigung.'
        } else if (error.name === 'NotFoundError') {
          this.error.message =
            'Keine Kamera gefunden. Bitte eine Kamera anschließen.'
        } else if (
          error.name === 'NotSupportedError' ||
          error.name === 'InsecureContextError'
        ) {
          this.error.message = 'Verbindung zum Server muss sicher sein. (HTTPS)'
        } else if (error.name === 'NotReadableError') {
          this.error.message = 'Wird die Kamera bereits verwendet?'
        } else if (error.name === 'OverconstrainedError') {
          this.error.message = 'Kamera kann nicht verwendet werden.'
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error.message = 'Dieser Browser unterstützt keine Kamera'
        } else {
          this.error.message = `Ein Fehler ist aufgetreten: ${error.message}`
        }
      } finally {
        this.showScanConfirmation = this.camera === 'off'
        this.loading = false
      }
    },
  },
}
</script>
