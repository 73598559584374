import axios from 'axios'

export async function getDocumentById(wf_cid) {
  const response = await axios.get(`/v1/crm/documents/${wf_cid}`)

  return response
}

export async function printDocument(item) {
  item.printLoading = true

  const response = await axios.get(`/v1/crm/documents/${item.wf_cid}/pdf`, {
    responseType: 'blob',
  })

  const url = window.URL.createObjectURL(
    new Blob([response.data], { type: 'application/pdf' })
  )

  // const pdfDownloadPopUp = window.open(url, '_blank');
  // if(pdfDownloadPopUp) {
  //   pdfDownloadPopUp.focus();
  // }
  // // Fallback for when popup isn't allowed
  // else {
  const pdfLink = document.createElement('a')
  pdfLink.href = url
  pdfLink.download = item.name + '.pdf'
  pdfLink.target = '_blank'
  document.body.appendChild(pdfLink)
  pdfLink.click()
  document.body.removeChild(pdfLink)
  // }

  item.printLoading = false

  return response
}

export async function getAvailableUpgrades(wf_cid) {
  const response = await axios.get(`/v1/business/document/${wf_cid}/upgrades`, )

  return response
}

export async function upgradeDocument(wf_cid, upgrade) {
  const response = await axios.patch(
    `/v1/business/document/${wf_cid}/upgrade`,
    upgrade
  )

  return response
}

export async function getDocumentInteractions(wf_cid) {
  const response = await axios.get(`/v1/crm/documents/${wf_cid}/interactions`)

  return response.data
}
export async function getDocumentSubjects(wf_cid) {
  const response = await axios.get(
    `/v1/crm/documents/${wf_cid}/interactions/subjects`
  )

  return response.data
}

export async function AddDocumentInteraction(interaction) {
  await axios
    .post(`/v1/crm/documents/${interaction.wf_cid}/interactions`, interaction)
    .then((response) => {
      return response.data
    })
}

export async function editDocumentInteraction(interaction) {
  const response = await axios.put(
    `/v1/crm/documents/${interaction.wf_cid}/interactions`,
    interaction
  )

  return response.data
}

export async function deleteDocumentInteraction(wf_cid, interaction) {
  const response = await axios.delete(
    `/v1/crm/documents/${wf_cid}/interactions`,
    {
      data: interaction,
    }
  )

  return response.data
}
