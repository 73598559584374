import axios from 'axios'

export async function getContactPersonsForAddress(lfdnr) {
  const response = await axios.get(`/v1/crm/addresses/${lfdnr}/contactPersons`)

  return response.data
}

export async function createContactPersonForAddress(lfdnr, contactPerson) {
  const response = await axios.post(
    `/v1/crm/addresses/${lfdnr}/contactPersons`,
    contactPerson
  )

  return response.data
}

export async function updateContactPersonForAddress(lfdnr, contactPerson) {
  const response = await axios.put(
    `/v1/crm/addresses/${lfdnr}/contactPersons`,
    contactPerson
  )

  return response.data
}

export async function deleteContactPersonForAddress(lfdnr, key) {
  const response = await axios.delete(
    `/v1/crm/addresses/${lfdnr}/contactPersons`,
    { data: key }
  )

  return response.data
}

export async function getFunctions() {
  const response = await axios.get('/v1/crm/contactPersons/functions')

  return response.data
}

export async function getDepartments() {
  const response = await axios.get('/v1/crm/contactPersons/departments')

  return response.data
}
