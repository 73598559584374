import axios from 'axios'

export async function getCustomerGroupsForAddress(lfdnr) {
  const response = await axios.get(`/v1/crm/addresses/${lfdnr}/customerGroups`)

  return response.data
}

export async function createCustomerGroupForAddress(lfdnr, addressTariff) {
  const response = await axios.post(
    `/v1/crm/addresses/${lfdnr}/customerGroups`,
    addressTariff
  )

  return response.data
}

export async function updateCustomerGroupForAddress(lfdnr, oldCode, toUpdate) {
  const response = await axios.put(
    `/v1/crm/addresses/${lfdnr}/customerGroups/${oldCode}`,
    toUpdate
  )

  return response.data
}

export async function deleteCustomerGroupForAddress(lfdnr, customerGroupCode) {
  const response = await axios.delete(
    `/v1/crm/addresses/${lfdnr}/customerGroups/${customerGroupCode}`
  )

  return response.data
}
