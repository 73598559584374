import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router/index.js'
import store from '@/store/index.js'
import vuetify from '@/plugins/vuetify.js'
import Toast from 'vue-toastification'
import VueViewer from 'v-viewer'
import setupInterceptors from '@/services/axios/setupInterceptors.js'
import '@/plugins/lba.js'
import '@/plugins/leaflet'
import '@/plugins/wysiwyg-editor'
import '@/plugins/apexcharts'
import '@/registerServiceWorker'
import 'vue-toastification/dist/index.css'
import 'viewerjs/dist/viewer.css'
import VueScrollactive from 'vue-scrollactive'
import VueSmartWidget from 'vue-smart-widget'
import VueGridLayout from 'vue-grid-layout'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'

import {
  renewBearerWithRefreshToken,
  getRefreshTokenFromLocalStorage
} from '@/services/core/authService.js'

// The magic is here
import '@/App.scss'

Vue.config.productionTip = false

setupInterceptors(store)
Vue.use(VueScrollactive)
Vue.use(VueViewer)
Vue.use(VueSmartWidget)
Vue.use(VueGridLayout)

/*  REQUIRED FOR CRM Openstreetmap */
// eslint-disable-next-line
delete L.Icon.Default.prototype._getIconUrl
// eslint-disable-next-line
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})
/*  END OF REQUIRED FOR CRM Openstreetmap */

Vue.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true
})

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
  locale: 'de-AT',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true
})

startApp()

// async start function to enable waiting for refresh token call
async function startApp () {
  // attempt to auto refresh token before startup
  try {
    console.debug('refreshing bearer on startup')
    await renewBearerWithRefreshToken(getRefreshTokenFromLocalStorage())
  } catch {
    console.log('refreshing token on startup failed')
    // catch error to start app on success or failure
  }

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App)
  }).$mount('#app')
}
