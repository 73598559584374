import axios from 'axios'

export async function getAppMasterData(app) {
  const response = await axios.get(`/v1/settings/master-data/${app}`)

  return response.data
}
export async function getMasterData(type) {
  const response = await axios.get(`/v1/settings/master-data/category/${type}`)

  return response.data
}

export async function saveMasterData(category, masterData) {
  const response = await axios.put(
    `/v1/settings/master-data/${category}`,
    masterData
  )

  return response.data
}

export async function deleteMasterData(category, masterData) {
  const response = await axios.delete(`/v1/settings/master-data/${category}`, {
    data: masterData,
  })

  return response.data
}
