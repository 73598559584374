export default {
  state: {
    addressType: [],
    customerGroup: [],
    locations: [],
  },
  mutations: {
    SET_ADDRESS_TYPES(state, addressType) {
      state.addressType = addressType
    },
    SET_CUSTOMER_GROUP(state, customerGroup) {
      state.customerGroup = customerGroup
    },
    SET_LOCATIONS(state, locations) {
      state.locations = locations
    },
  },
  actions: {
    setAddressType({ commit }, newAddresstype) {
      commit('SET_ADDRESS_TYPES', newAddresstype)
    },
    setCustomerGroup({ commit }, newCustomerGroup) {
      commit('SET_CUSTOMER_GROUP', newCustomerGroup)
    },
    setLocations({ commit }, newLocations) {
      commit('SET_LOCATIONS', newLocations)
    },
  },
  getters: {
    getAddressType(state) {
      return state.addressTypes
    },
    getCustomerGroup(state) {
      return state.customerGroup
    },
    getLocations(state) {
      return state.locations
    },
  },
}
