import {
  loadAddressTypes,
  loadAddAddressOptions,
  loadPatientOptions,
  loadEmployeeOptions,
  loadDoctorOptions,
  loadCompanyOptions,
  loadSupplierOptions,
  loadHealthInsuranceCompanyOptions,
  searchDoctors,
  checkForDuplicatePersons,
  checkForDuplicateNonPersons,
  saveNewPerson,
  saveNewNonPerson,
} from '@/services/crm/addressService.js'
import { Person, NonPerson } from '@/models/crm/address.js'

export default {
  state: {
    navList: {
      personNavList: [],
      nonPersonNavList: [],
    },
    addressTypes: [],
    newPerson: new Person(),
    newNonPerson: new NonPerson(),
    validAddress: false,
    validPatient: false,
    validRelative: false,
    validEmployee: false,
    validDoctor: false,
    validGeneralInfo: false,
    validCompany: true,
    validSupplier: true,
    validHealthInsuranceCompany: true,
    addressOptions: {},
    patientOptions: {
      healthInsuranceCompanies: null,
      insuredGroups: null,
      familyDoctors: null,
    },
    employeeOptions: {},
    doctorOptions: {},
    companyOptions: {},
    supplierOptions: {},
    healthInsuranceCompanyOptions: {},
    duplicates: {
      persons: null,
      nonPersons: null,
    },
    lastCreated: {
      person: new Person(),
      nonPerson: new NonPerson(),
    },
  },
  mutations: {
    SET_ADDRESSTYPES(state, address) {
      state.addressTypes = address.address
    },
    SET_DEFAULT_OPTIONS(state, options) {
      state.addressOptions = options.options
    },
    SET_PATIENT_DEFAULT_OPTIONS(state, patientOptions) {
      state.patientOptions.healthInsuranceCompanies =
        patientOptions.options.healthInsuranceCompanies
      state.patientOptions.insuredGroups = patientOptions.options.insuredGroups
    },
    SET_PATIENT_FAMILY_DOCTORS(state, doctors) {
      state.patientOptions.familyDoctors = doctors.doctors
    },
    SET_EMPLOYEE_DEFAULT_OPTIONS(state, options) {
      state.employeeOptions = options.options
    },
    SET_DOCTOR_DEFAULT_OPTIONS(state, options) {
      state.doctorOptions = options.options
    },
    SET_COMPANY_DEFAULT_OPTIONS(state, options) {
      state.companyOptions = options.options
    },
    SET_SUPPLIER_DEFAULT_OPTIONS(state, options) {
      state.supplierOptions = options.options
    },
    SET_HEALTH_INSURANCE_COMPANY_OPTIONS(state, options) {
      state.healthInsuranceCompanyOptions = options.options
    },
    SET_DUPLICATE_PERSONS(state, persons) {
      state.duplicates.persons = persons.results
    },
    SET_DUPLICATE_NON_PERSONS(state, persons) {
      state.duplicates.nonPersons = persons.results
    },
    SET_LAST_CREATED_PERSON(state, person) {
      state.lastCreated.person = person.results
    },
    updateValidAddress(state, isValid) {
      state.validAddress = isValid
    },
    updateValidPatient(state, isValid) {
      state.validPatient = isValid
    },
    updateValidRelative(state, isValid) {
      state.validRelative = isValid
    },
    updateValidEmployee(state, isValid) {
      state.validEmployee = isValid
    },
    updateValidDoctor(state, isValid) {
      state.validDoctor = isValid
    },
    updateValidGeneralInfo(state, isValid) {
      state.validGeneralInfo = isValid
    },
    updateValidCompany(state, isValid) {
      state.validCompany = isValid
    },
    updateValidSupplier(state, isValid) {
      state.validSupplier = isValid
    },
    updatePersonNavList(state) {
      state.navList.personNavList = [
        {
          show: state.newPerson.isConsumer,
          title: state.addressTypes.find(
            (type) => type.fldName === 'kd_konsument'
          ).fldDisplayName,
          ref: 'allgemein',
        },
        {
          show: state.newPerson.isPatient,
          title: state.addressTypes.find(
            (type) => type.fldName === 'kd_patient'
          ).fldDisplayName,
          ref: 'patient',
        },
        {
          show: state.newPerson.isDoctor,
          title: state.addressTypes.find((type) => type.fldName === 'kd_arzt')
            .fldDisplayName,
          ref: 'arzt',
        },
        {
          show: state.newPerson.isEmployee,
          title: state.addressTypes.find(
            (type) => type.fldName === 'kd_verkaeufer'
          ).fldDisplayName,
          ref: 'verkaeufer',
        },
        {
          show: state.newPerson.isAdultRepresentation,
          title: state.addressTypes.find(
            (type) => type.fldName === 'kd_sachwalter'
          ).fldDisplayName,
          ref: 'allgemein',
        },
        {
          show: state.newPerson.isInterested,
          title: state.addressTypes.find(
            (type) => type.fldName === 'kd_interessent'
          ).fldDisplayName,
          ref: 'allgemein',
        },
      ]
    },
    updateNonPersonNavList(state) {
      state.navList.nonPersonNavList = [
        {
          show: state.newNonPerson.isCompany,
          title: state.addressTypes.find((type) => type.fldName === 'kd_kunde')
            .fldDisplayName,
          ref: 'kunde',
        },
        {
          show: state.newNonPerson.isSupplier,
          title: state.addressTypes.find(
            (type) => type.fldName === 'kd_lieferant'
          ).fldDisplayName,
          ref: 'lieferant',
        },
        {
          show: state.newNonPerson.isHealthInsuranceCompany,
          title: state.addressTypes.find(
            (type) => type.fldName === 'kd_krankenkasse'
          ).fldDisplayName,
          ref: 'allgemein',
        },
        {
          show: state.newNonPerson.isResidence,
          title: state.addressTypes.find((type) => type.fldName === 'kd_heim')
            .fldDisplayName,
          ref: 'allgemein',
        },
        {
          show: state.newNonPerson.isAdultRepresentation,
          title: state.addressTypes.find(
            (type) => type.fldName === 'kd_sachwalter'
          ).fldDisplayName,
          ref: 'allgemein',
        },
      ]
    },

    updateValidHealthInsuranceCompany(state, isValid) {
      state.validHealthInsuranceCompany = isValid
    },
    resetNewPerson(state) {
      state.newPerson = new Person()
      state.duplicates.persons = null
    },
    resetNewNonPerson(state) {
      state.newNonPerson = new NonPerson()
      state.duplicates.nonPersons = null
    },
    setValuesToTestValues(state) {
      state.newPerson = { ...Person.testValues }
      state.newNonPerson = { ...NonPerson.testValues }
    },
  },
  actions: {
    async loadAddressTypes({ commit }) {
      await loadAddressTypes().then((response) => {
        commit('SET_ADDRESSTYPES', { address: response })
      })
    },
    async loadAddAddressOptions({ commit }) {
      await loadAddAddressOptions().then((data) => {
        commit('SET_DEFAULT_OPTIONS', { options: data })
      })
    },
    async loadPatientOptions({ commit }) {
      await loadPatientOptions().then((data) => {
        commit('SET_PATIENT_DEFAULT_OPTIONS', { options: data })
      })
    },
    async loadEmployeeOptions({ commit }) {
      await loadEmployeeOptions().then((data) => {
        commit('SET_EMPLOYEE_DEFAULT_OPTIONS', { options: data })
      })
    },
    async loadDoctorOptions({ commit }) {
      await loadDoctorOptions().then((data) => {
        commit('SET_DOCTOR_DEFAULT_OPTIONS', { options: data })
      })
    },
    async loadCompanyOptions({ commit }) {
      await loadCompanyOptions().then((data) => {
        commit('SET_COMPANY_DEFAULT_OPTIONS', { options: data })
      })
    },
    async loadSupplierOptions({ commit }) {
      await loadSupplierOptions().then((data) => {
        commit('SET_SUPPLIER_DEFAULT_OPTIONS', { options: data })
      })
    },
    async loadHealthInsuranceCompanyOptions({ commit }) {
      await loadHealthInsuranceCompanyOptions().then((data) => {
        commit('SET_HEALTH_INSURANCE_COMPANY_OPTIONS', { options: data })
      })
    },
    async loadPatientFamilyDoctors({ commit }, payload) {
      await searchDoctors(payload).then((data) => {
        commit('SET_PATIENT_FAMILY_DOCTORS', { doctors: data })
      })
    },
    async checkDuplicatePersons({ commit }, payload) {
      await checkForDuplicatePersons(payload).then((data) => {
        commit('SET_DUPLICATE_PERSONS', { results: data })
      })
    },
    async checkDuplicateNonPersons({ commit }, payload) {
      await checkForDuplicateNonPersons(payload).then((data) => {
        commit('SET_DUPLICATE_NON_PERSONS', { results: data })
      })
    },
    async saveNewPerson(context, payload) {
      const result = await saveNewPerson(payload)
      return result
    },
    async saveNewNonPerson(context, payload) {
      const result = await saveNewNonPerson(payload)
      return result
    },
  },
}
