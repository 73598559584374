export function formatNumber(value, digits) {
  const formattedNumber = Number(value).toFixed(digits)
  return formatCurrency(formattedNumber, digits)
}

export function formatCurrency(value, digits) {
  return Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: digits,
  })
    .format(value)
    .replace('€', '')
}

export function formatDate(value) {
  return new Date(value).toLocaleDateString('uk-Uk')
}
