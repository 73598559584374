import {
  getAppMasterData,
  getMasterData,
} from '@/services/settings/masterDataService.js'

export default {
  state: {
    masterDataApp: [],
    masterData: [],
  },
  mutations: {
    SET_MASTER_DATA_APP(state, masterDataApp) {
      state.masterDataApp = masterDataApp
    },
    SET_MASTER_DATA(state, masterData) {
      state.masterData = masterData
    },
  },
  actions: {
    async loadMasterDataApp({ commit }, app) {
      commit('SET_MASTER_DATA_APP', await getAppMasterData(app))
    },
    async loadMasterData({ commit }, type) {
      commit('SET_MASTER_DATA', await getMasterData(type))
    },
  },
}
