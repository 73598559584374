import {
  getCustomerGroupsForAddress,
  createCustomerGroupForAddress,
  updateCustomerGroupForAddress,
  deleteCustomerGroupForAddress,
} from '@/services/crm/customerGroupService.js'
import { getCustomerGroups } from '@/services/crm/addressService.js'

export default {
  state: {
    customerGroups: [],
    availableCustomerGroups: [],
  },
  mutations: {
    SET_CUSTOMER_GROUPS(state, customerGroups) {
      state.customerGroups = customerGroups
    },
    ADD_CUSTOMER_GROUP(state, customerGroup) {
      state.customerGroups.push(customerGroup)
    },
    REMOVE_CUSTOMER_GROUP(state, customerGroup) {
      state.customerGroups = state.customerGroups.filter((other) => {
        const otherKey = other?.customerGroup?.key
        const customerGroupKey = customerGroup?.customerGroup?.key
        return (
          false ===
          (otherKey?.bezeichnung == customerGroupKey?.bezeichnung &&
            otherKey?.code == customerGroupKey?.code)
        )
      })
    },
    SET_AVAILABLE_CUSTOMER_GROUPS(state, availableCustomerGroups) {
      state.availableCustomerGroups = availableCustomerGroups
    },
  },
  actions: {
    async loadCustomerGroups({ commit }, { lfdnr }) {
      await getCustomerGroupsForAddress(lfdnr).then((data) =>
        commit('SET_CUSTOMER_GROUPS', data)
      )
    },

    saveCustomerGroup({ commit }, { lfdnr, customerGroup }) {
      return createCustomerGroupForAddress(lfdnr, customerGroup)
        .then((response) => {
          if (response == null) {
            commit('REMOVE_CUSTOMER_GROUP', customerGroup)
          } else {
            commit('ADD_CUSTOMER_GROUP', customerGroup)
          }
        })
        .catch((err) => {
          throw err
        })
    },

    updateCustomerGroupRelation(
      { dispatch },
      { lfdnr, oldCode, customerGroup }
    ) {
      return updateCustomerGroupForAddress(lfdnr, oldCode, customerGroup)
        .then(() => dispatch('loadCustomerGroups', { lfdnr }))
        .catch((err) => {
          throw err
        })
    },

    removeCustomerGroupRelation(
      { commit, dispatch },
      { lfdnr, customerGroup }
    ) {
      commit('REMOVE_CUSTOMER_GROUP', customerGroup)
      deleteCustomerGroupForAddress(
        lfdnr,
        customerGroup.customerGroup?.key?.code
      ).then(() => dispatch('loadCustomerGroups', { lfdnr }))
    },

    loadAvailableCustomerGroups({ commit }) {
      getCustomerGroups().then((data) =>
        commit('SET_AVAILABLE_CUSTOMER_GROUPS', data)
      )
    },
  },
}
