import {
  changeNotificationStatus,
  getNotifications,
} from '@/services/core/appService.js'

export default {
  state: {
    notifications: [],
  },
  mutations: {
    SET_NOTIFICATIONS(state, payload) {
      state.notifications = payload
    },
  },
  actions: {
    async GET_ALL_NOTIFICATIONS({ commit }) {
      await getNotifications().then((notifications) => {
        commit('SET_NOTIFICATIONS', notifications)
      })
    },
    async CHANGE_READ_STATUS_NOTIFICATION({ dispatch }, nId) {
      changeNotificationStatus(nId).then(() => {
        dispatch('GET_ALL_NOTIFICATIONS')
      })
    },
  },
}
