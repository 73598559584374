import axios from 'axios'

/* General */
export async function createTask(task) {
  const response = await axios.put('/v1/task', task)

  return response.data
}

export async function getTaskPreviews() {
  const response = await axios.get('/v1/task/previews')

  return response.data
}

export async function getTaskPreviewsForUser() {
  const response = await axios.get('/v1/task/user/previews')

  return response.data
}

export async function getTaskForLfdnr(lfdnr) {
  const response = await axios.get(`/v1/task/${lfdnr}`)

  return response.data
}

export async function getAssignableUsers() {
  const response = await axios.get('/v1/task/assignable/users')

  return response.data
}

export async function getAssignableWorkGroups() {
  const response = await axios.get('/v1/task/assignable/work-groups')

  return response.data
}

export async function deleteTask(taskId) {
  const response = await axios.delete('/v1/task/' + taskId)

  if (response.status != 200) {
    throw axios.AxiosError.ERR_BAD_REQUEST
  } else {
    return response.data
  }
}

export async function getTaskHistory(taskId) {
  const response = await axios.get(`/v1/task/history/${taskId}`)

  return response.data
}

/* Task Properties */
export async function getTaskTitles() {
  const response = await axios.get('/v1/task-resources/titles')

  if (response.status != 200) {
    throw axios.AxiosError.ERR_BAD_REQUEST
  } else {
    return response.data
  }
}

export async function editTaskTitle(taskId, title) {
  const response = await axios.patch(`/v1/task/${taskId}/title?value=${title}`)

  if (response.status != 200) {
    throw axios.AxiosError.ERR_BAD_REQUEST
  } else {
    return response.data
  }
}

export async function editTaskDescription(taskId, text) {
  const response = await axios.patch(`/v1/task/${taskId}/description`, text, {
    headers: {
      'Content-Type': 'text/plain',
    },
  })

  if (response.status != 200) {
    throw axios.AxiosError.ERR_BAD_REQUEST
  } else {
    return response.data
  }
}

export async function updateTaskAssignees(taskId, assignees) {
  const response = await axios.patch(`/v1/task/${taskId}/assignees`, assignees)

  return response.data
}

export async function updateTaskObjects(taskId, objects) {
  const response = await axios.patch(`/v1/task/${taskId}/objects`, objects)

  return response.data
}

export async function editTaskDueDate(taskId, dueDate) {
  const response = await axios.patch(
    `/v1/task/${taskId}/due-date?value=${dueDate}T00:00:00`
  )

  if (response.status != 200) {
    throw axios.AxiosError.ERR_BAD_REQUEST
  } else {
    return response.data
  }
}

export async function getTaskStates() {
  const response = await axios.get('/v1/task-resources/states')

  if (response.status != 200) {
    throw axios.AxiosError.ERR_BAD_REQUEST
  } else {
    return response.data
  }
}

export async function editTaskStatus(taskId, status) {
  const response = await axios.patch(`/v1/task/${taskId}/state`, status)

  if (response.status != 200) {
    throw axios.AxiosError.ERR_BAD_REQUEST
  } else {
    return response.data
  }
}

export async function getTaskBaseAddresses(lfdnrs) {
  const response = await axios.post('/v1/task-resources/base/addresses', lfdnrs)

  return response.data
}

export async function editTaskNotificationState(taskId, notificationsState) {
  const response = await axios.patch(
    `/v1/task/${taskId}/notifications-active?value=${notificationsState}`
  )

  if (response.status != 200) {
    throw axios.AxiosError.ERR_BAD_REQUEST
  } else {
    return response.data
  }
}

/* Comment */
export async function addComment(payLoad) {
  let response = await axios.put('/v1/task-comments', payLoad)

  return response.data
}

export async function editComment(taskId, lfdnr, text) {
  const response = await axios.put('/v1/task-comments', {
    taskId,
    lfdnr,
    text,
  })

  if (response.status != 200) {
    throw axios.AxiosError.ERR_BAD_REQUEST
  } else {
    return response.data
  }
}

export async function deleteComment(taskCommentId) {
  const response = await axios.delete('/v1/task-comments/' + taskCommentId)

  if (response.status != 200) {
    throw axios.AxiosError.ERR_BAD_REQUEST
  } else {
    return response.data
  }
}
