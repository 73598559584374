import {
  getCrmFunctions,
  getCrmDepartments,
} from '@/services/crm/settingsService.js'

export default {
  state: {
    functions: [],
    departments: [],
  },
  mutations: {
    SET_FUNCTIONS(state, functions) {
      state.functions = functions
    },
    SET_DEPARTMENTS(state, departments) {
      state.departments = departments
    },
  },
  actions: {
    async GET_FUNCTIONS({ commit }) {
      commit('SET_FUNCTIONS', await getCrmFunctions())
    },
    async GET_DEPARTMENTS({ commit }) {
      commit('SET_DEPARTMENTS', await getCrmDepartments())
    },
  },
}
