export default {
  state: {
    currentYear: new Date().getFullYear(),
    minYear: -1,
    yearStart: -1,
    yearEnd: -1,
    yearItems: [],
    currentTab: '',
    selectedYear: -1,
    monthly: false,
    loading: false,
  },
  mutations: {
    SET_MIN_YEAR(state, minYear) {
      state.minYear = minYear
    },
    SET_YEAR_START(state, yearStart) {
      state.yearStart = yearStart
    },
    SET_YEAR_END(state, yearEnd) {
      state.yearEnd = yearEnd
    },
    SET_YEAR_ITEMS(state, yearItems) {
      state.yearItems = yearItems
    },
    SET_CURRENT_TAB(state, currentTab) {
      state.currentTab = currentTab
    },
    SET_SELECTED_YEAR(state, selectedYear) {
      state.selectedYear = selectedYear
    },
    SET_MONTHLY(state, monthly) {
      state.monthly = monthly
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
  },
  actions: {
    setOfferMinYear({ commit }, newMinYear) {
      commit('SET_MIN_YEAR', newMinYear)
    },
    setOfferYearStart({ commit }, newYearStart) {
      commit('SET_YEAR_START', newYearStart)
    },
    setOfferYearEnd({ commit }, newYearEnd) {
      commit('SET_YEAR_END', newYearEnd)
    },
    setOfferYearItems({ commit }, newYearItems) {
      commit('SET_YEAR_ITEMS', newYearItems)
    },
    setOfferCurrentTab({ commit }, newCurrenTab) {
      commit('SET_CURRENT_TAB', newCurrenTab)
    },
    setOfferSelectedYear({ commit }, newSelectedYear) {
      commit('SET_SELECTED_YEAR', newSelectedYear)
    },
    setOfferMonthly({ commit }, newMonthly) {
      commit('SET_MONTHLY', newMonthly)
    },
    setLoading({ commit }, newLoading) {
      commit('SET_LOADING', newLoading)
    },
  },
  getters: {
    getOfferMinYear(state) {
      return state.minYear
    },
    getOfferYearStart(state) {
      return state.yearStart
    },
    getOfferYearEnd(state) {
      return state.yearEnd
    },
    getOfferYearItems(state) {
      return state.yearItems
    },
    getOfferCurrentTab(state) {
      return state.currentTab
    },
    getOfferSelectedYear(state) {
      return state.selectedYear
    },
    getOfferMonthly(state) {
      return state.monthly
    },
    getOfferCurrentYear(state) {
      return state.currentYear
    },
    getLoading(state) {
      return state.loading
    },
  },
}
