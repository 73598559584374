export default {
  state: {
    customerFilter: {},
    distributorFilter: {},
    documentFilter: {},
    revenueComparisonFilter: {},
    revenueFilteredFilter: {},
  },
  mutations: {
    SET_CUSTOMER_FILTER(state, customerFilter) {
      state.customerFilter = customerFilter
    },
    SET_DISTRIBUTOR_FILTER(state, distributorFilter) {
      state.distributorFilter = distributorFilter
    },
    SET_DOCUMENT_FILTER(state, documentFilter) {
      state.documentFilter = documentFilter
    },
    SET_REVENUE_COMPARISON_FILTER(state, revenueComparisonFilter) {
      state.revenueComparisonFilter = revenueComparisonFilter
    },
    SET_REVENUE_FILTERED_FILTER(state, revenueFilteredFilter) {
      state.revenueFilteredFilter = revenueFilteredFilter
    },
  },
  actions: {
    setCustomerFilter({ commit }, customerFilter) {
      commit('SET_CUSTOMER_FILTER', customerFilter)
    },
    setDistributorFilter({ commit }, distributorFilter) {
      commit('SET_DISTRIBUTOR_FILTER', distributorFilter)
    },
    setDocumentFilter({ commit }, documentFilter) {
      commit('SET_DOCUMENT_FILTER', documentFilter)
    },
    setRevenueComparisonFilter({ commit }, revenueComparisonFilter) {
      commit('SET_REVENUE_COMPARISON_FILTER', revenueComparisonFilter)
    },
    setRevenueFilteredFilter({ commit }, revenueFilteredFilter) {
      commit('SET_REVENUE_FILTERED_FILTER', revenueFilteredFilter)
    },
  },
}
