<template>
  <LBADialog
    ref="dialog"
    :max-width="330"
    :centered-content="true"
    :persistent="true"
  >
    <template #title>{{ dialogText }}</template>
    <template #activator="{ on, attrs }"
      ><slot name="activator"
        ><LBAButton v-bind="attrs" :buttonStyle="'default'" v-on="on"
          ><v-icon
            v-if="iconPosition == 'left' && iconPosition != 'none'"
            class="mr-2"
            >mdi-clock</v-icon
          >{{ buttonText
          }}<v-icon
            v-if="iconPosition == 'right' && iconPosition != 'none'"
            class="ml-2"
            >mdi-clock</v-icon
          ></LBAButton
        ></slot
      ></template
    >

    <template #content>
      <v-text-field
        v-model="selectedTime"
        maxlength="5"
        type="time"
        step="300"
        style="width: 55px"
        class="text-justify text-center"
        @click="switchToHourView"
        @change="roundToNearestFiveMinutes('up')"
      ></v-text-field>

      <v-time-picker
        v-model="selectedTime"
        :active-picker.sync="activePicker"
        :allowed-minutes="allowedStep"
        :min="minimumTime"
        :max="maximumTime"
        format="24hr"
        view="minute"
        no-title
        color="primary"
        @click:minute="switchToHourView"
        @change="emitChange()"
      ></v-time-picker>
    </template>
    <template #success>
      <!--Button welcher Datepicker schließt und das ausgewählte Datum speichert-->
      <LBAButton @click="datePicked(), close()">OK</LBAButton>
    </template>
  </LBADialog>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Date],
      default: null,
    },
    buttonText: {
      type: String,
      default: 'Uhrzeit wählen',
    },
    dialogText: {
      type: String,
      default: '',
    },
    iconPosition: {
      type: String,
      default: 'none',
    },
    minimumTime: {
      type: String,
      default: '00:00',
    },
    maximumTime: {
      type: String,
      default: '23:59',
    },
  },
  data() {
    return {
      selectedTime: '',
      datePicker: false,
      activePicker: '',
      date: '',
      allowedStep: (m) => m % 5 === 0,
    }
  },
  watch: {
    value(newValue) {
      this.selectedTime = newValue // Update selectedDate when the value prop changes
    },
  },
  mounted() {
    // Initialize selectedDate when the component is mounted
    this.selectedTime = this.value
  },
  methods: {
    switchToHourView() {
      this.activePicker = 'HOUR'
    },
    close() {
      this.$refs.dialog.closeDialog()
    },
    datePicked() {
      this.$emit('timePicked', this.selectedTime)
    },
    roundToNearestFiveMinutes(roundingMode = 'up') {
      let timeString = this.selectedTime

      const [hours, minutes] = timeString.split(':').map(Number)
      const remainder = minutes % 5

      // If remainder is 0, the time is already a multiple of 5, so return it as is
      if (remainder === 0) {
        return timeString
      }

      // Calculate the minutes to add or subtract to reach the nearest multiple of 5
      let minutesAdjustment = 0
      if (roundingMode === 'up') {
        minutesAdjustment = 5 - remainder
      } else if (roundingMode === 'down') {
        minutesAdjustment = -remainder
      }

      // Calculate the rounded time
      const roundedMinutes = (minutes + minutesAdjustment + 60) % 60
      const roundedHours =
        (hours + Math.floor((minutes + minutesAdjustment) / 60) + 24) % 24

      // Pad the hours and minutes with leading zeros if necessary
      const roundedHoursString = String(roundedHours).padStart(2, '0')
      const roundedMinutesString = String(roundedMinutes).padStart(2, '0')

      // Return the rounded time in HH:mm format
      this.dateTime.time = `${roundedHoursString}:${roundedMinutesString}`
    },
    emitChange() {
      this.$emit('input', this.selectedTime)
    },
  },
}
</script>
