<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ attrs }">
      <v-text-field
        v-if="iconPosition == 'right'"
        v-model="time"
        background-color="white"
        :dense="dense"
        outlined
        :label="label"
        append-icon="mdi-clock"
        :rules="ruleSet"
        v-bind="attrs"
        @click:append="menu = true"
        @blur=";(time = parseTime(time)), datePicked()"
      ></v-text-field>
      <v-text-field
        v-else-if="iconPosition == 'left'"
        v-model="time"
        background-color="white"
        :dense="dense"
        outlined
        :label="label"
        prepend-icon="mdi-clock"
        :rules="ruleSet"
        v-bind="attrs"
        @click:prepend="menu = true"
        @blur=";(time = parseTime(time)), datePicked()"
      ></v-text-field>
      <v-text-field
        v-else
        v-model="time"
        :dense="dense"
        outlined
        background-color="white"
        :label="label"
        :rules="ruleSet"
        v-bind="attrs"
        @click="menu = true"
        @blur=";(time = parseTime(time)), datePicked()"
      ></v-text-field>
    </template>
    <v-card class="px-0 py-0 mx-0 my-0">
      <v-card-text>
        <v-text-field
          v-model="time"
          maxlength="5"
          type="time"
          step="300"
          style="width: 55px"
          class="text-justify text-center ml-3"
          @click="switchToHourView"
          @change="roundToNearestFiveMinutes('up')"
        ></v-text-field>

        <v-time-picker
          v-model="time"
          :active-picker.sync="activePicker"
          :allowed-minutes="allowedStep"
          :min="minimumTime"
          :max="maximumTime"
          format="24hr"
          view="minute"
          no-title
          color="primary"
          @change="emitChange()"
          @click:minute="switchToHourView"
        >
        </v-time-picker>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu = false"> Abbrechen </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.menu.save(time), datePicked()"
        >
          OK
        </v-btn>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import { isValidTime } from '@/services/calendar/dateTimeService.js'
export default {
  props: {
    value: {
      type: [String, Date],
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Uhrzeit wählen',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    iconPosition: {
      type: String,
      default: 'right',
    },
    minimumTime: {
      type: String,
      default: '00:00',
    },
    maximumTime: {
      type: String,
      default: '23:59',
    },
  },
  data() {
    return {
      selectedTime: '',
      time: '',
      activePicker: '',
      menu: false,
      allowedStep: (m) => m % 5 === 0,
    }
  },
  computed: {
    ruleSet() {
      // Combine the legalDate rule with the custom rules provided via the :rules prop
      return [this.legalTime, ...this.rules]
    },
  },
  watch: {
    value(newValue) {
      this.time = newValue // Update selectedDate when the value prop changes
    },
    time() {
      this.$emit('input', this.time)
    },
  },
  mounted() {
    // Initialize selectedDate when the component is mounted
    this.time = this.value
  },
  methods: {
    switchToHourView() {
      this.activePicker = 'HOUR'
    },
    datePicked() {
      const valid = this.ruleSet.every((rule) => rule(this.time) === true)

      if (!valid) return

      this.$emit('timePicked', this.time)
    },
    parseTime(time) {
      if (!time) return null
      if (time.includes(':')) {
        return time
      } else {
        return this.parseShortTime(time)
      }
    },
    parseShortTime(time) {
      //e.g.: 1315 --> 13:15
      const hour = time.slice(0, 2)
      const minute = time.slice(2, 4)

      return `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`
    },
    legalTime(value) {
      // Handle null or undefined value
      if (value === null || value === undefined) {
        return 'Keine gültige Uhrzeit'
      }

      if (value == '') {
        return true
      }

      const [hour, minute] = value.split(':')

      return (
        (isValidTime(hour, minute) &&
          this.checkTimeWithinConstraints(hour, minute)) ||
        'Kein gültiges Datum!'
      )
    },
    checkTimeWithinConstraints(hour, minute) {
      const [minHour, minMinute] = this.minimumTime.split(':').map(Number)
      const [maxHour, maxMinute] = this.maximumTime.split(':').map(Number)

      // Convert the given time, minTime, and maxTime into Date objects
      const givenTime = new Date(0, 0, 0, hour, minute)
      const minTimeObj = new Date(0, 0, 0, minHour, minMinute)
      const maxTimeObj = new Date(0, 0, 0, maxHour, maxMinute)

      // Check if the given time is within the range
      return givenTime >= minTimeObj && givenTime <= maxTimeObj
    },
    emitChange() {
      const valid = this.ruleSet.every((rule) => rule(this.time) === true)

      if (!valid) return

      this.$emit('input', this.time)
    },
  },
}
</script>
