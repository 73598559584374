<template>
  <div>
    <div class="activator" @click="openDialog">
      <slot name="activator"></slot>
    </div>

    <v-dialog
      v-model="internalValue"
      :width="width"
      :scrollable="scrollable"
      :max-width="maxWidth"
      :persistent="persistent || permanent"
      :hide-overlay="hideOverlay"
      @keydown.esc="escPressed"
      @click:outside="clickedOutside"
    >
      <v-card>
        <v-card-title class="ma-0 pa-0">
          <v-toolbar
            :class="{
              'primary--text': dialogType === 'default',
              'success--text': dialogType === 'success',
              'warning--text': dialogType === 'warning',
              'error--text': dialogType === 'error',
            }"
            top
            flat
            style="background-color: white"
          >
            <v-toolbar-title class="flex text-center">
              <slot v-if="dialogType == 'warning'" name="title" class="d-flex">
                Warnung
              </slot>

              <slot
                v-else-if="dialogType == 'error'"
                name="title"
                class="d-flex"
              >
                Fehler
              </slot>
              <slot v-else name="title" class="d-flex">{{ title }}</slot>
            </v-toolbar-title>
            <v-icon
              v-if="!permanent"
              :color="
                dialogType === 'success'
                  ? 'success'
                  : dialogType === 'warning'
                    ? 'warning'
                    : dialogType === 'error'
                      ? 'error'
                      : 'primary'
              "
              @click="closeDialog"
              >mdi-close</v-icon
            >
          </v-toolbar>
        </v-card-title>
        <v-divider
          :class="{
            'primary thick-divider': dialogType === 'default',
            'success thick-divider': dialogType === 'success',
            'warning thick-divider': dialogType === 'warning',
            'error thick-divider': dialogType === 'error',
          }"
        ></v-divider>

        <v-card-text
          :class="{
            'text-body-1 ': dialogType == 'error' || dialogType == 'warning',
            'text-center': centeredContent,
          }"
          class="mt-4"
        >
          <slot name="content"></slot>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <LBAButton
            v-if="cancelButton && !permanent"
            :buttonType="'error'"
            :buttonStyle="'text'"
            class="mr-2"
            @click="cancelDialog()"
          >
            <template #content>Abbrechen</template>
          </LBAButton>
          <slot name="actions"></slot>
          <slot name="success"></slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    // Value from v-model of the calling component
    value: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: [String, Number],
      default: '700',
    },
    width: {
      type: [String, Number],
      default: '100%',
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    permanent: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    hideOverlay: {
      type: Boolean,
      default: false,
    },
    dialogType: {
      type: String,
      default: 'default',
    },
    centeredContent: {
      type: Boolean,
      default: false,
    },
    cancelButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      internalValue: this.value,
    }
  },
  watch: {
    value(newValue) {
      this.internalValue = newValue
    },
  },
  methods: {
    cancelDialog() {
      this.$emit('cancel')
      this.closeDialog()
    },
    closeDialog() {
      // Emit an event to notify the parent component that the dialog is closed
      this.$emit('close')
      this.internalValue = false
    },
    escPressed() {
      this.$emit('keydown-esc')
    },
    clickedOutside() {
      this.$emit('click-outside')
    },
    openDialog() {
      this.internalValue = true
    },
  },
}
</script>

<style scoped>
.thick-divider {
  border-bottom-width: 2px; /* Adjust thickness as needed */
}
</style>
