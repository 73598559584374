import Vue from 'vue'
import LBAButton from '@/components/ui/buttons/LBAButton.vue'
import LBACard from '@/components/ui/cards/LBACard.vue'
import LBADialog from '@/components/ui/dialogs/LBADialog.vue'
import LBAExpansionPanel from '@/components/ui/expansion/LBAExpansionPanel.vue'
import LBAFilterExpansion from '@/components/ui/expansion/LBAFilterExpansion.vue'
import LBABarcodeScanner from '@/components/ui/scanner/LBABarcodeScanner.vue'
import LBADatepickerDialog from '@/components/ui/inputs/LBADatepickerDialog.vue'
import LBADatepickerInput from '@/components/ui/inputs/LBADatepickerInput.vue'
import LBADateTimepickerDialog from '@/components/ui/inputs/LBADateTimepickerDialog.vue'
import LBADateTimepickerInput from '@/components/ui/inputs/LBADateTimepickerInput.vue'
import LBATimepickerDialog from '@/components/ui/inputs/LBATimepickerDialog.vue'
import LBATimepickerInput from '@/components/ui/inputs/LBATimepickerInput.vue'

Vue.component('LBAButton', LBAButton)

Vue.component('LBACard', LBACard)

Vue.component('LBADialog', LBADialog)

Vue.component('LBAExpansionPanel', LBAExpansionPanel)
Vue.component('LBAFilterExpansion', LBAFilterExpansion)

Vue.component('LBADatepickerDialog', LBADatepickerDialog)
Vue.component('LBADatepickerInput', LBADatepickerInput)
Vue.component('LBADateTimepickerDialog', LBADateTimepickerDialog)
Vue.component('LBADateTimepickerInput', LBADateTimepickerInput)
Vue.component('LBATimepickerDialog', LBATimepickerDialog)
Vue.component('LBATimepickerInput', LBATimepickerInput)

Vue.component('LBABarcodeScanner', LBABarcodeScanner)
