import {
  getContactPersonsForAddress,
  createContactPersonForAddress,
  updateContactPersonForAddress,
  deleteContactPersonForAddress,
  getFunctions,
  getDepartments,
} from '@/services/crm/contactPersonService.js'

export default {
  state: {
    contacts: [],
    functions: [],
    departments: [],
  },
  mutations: {
    SET_CONTACTS(state, contacts) {
      state.contacts = contacts.map((c) => {
        if (c?.abteilung == null) return c
        else
          return {
            ...c,
            department: {
              id: null,
              department: c.abteilung,
            },
          }
      })
    },
    SET_FUNCTIONS(state, functions) {
      state.functions = functions.sort()
    },
    SET_DEPARTMENTS(state, departments) {
      state.departments = departments
    },
  },
  actions: {
    loadContacts({ commit }, { lfdnr }) {
      getContactPersonsForAddress(lfdnr).then((response) => {
        commit('SET_CONTACTS', response)
      })
    },
    saveContact({ dispatch }, { lfdnr, contact }) {
      createContactPersonForAddress(lfdnr, contact).then(() => {
        dispatch('loadContacts', { lfdnr })
        dispatch('getFunktionen')
        dispatch('getDepartments')
      })
    },
    updateContact({ dispatch }, { lfdnr, contact }) {
      updateContactPersonForAddress(lfdnr, contact).then(() => {
        dispatch('loadContacts', { lfdnr })
        dispatch('getFunktionen')
        dispatch('getDepartments')
      })
    },
    deleteContact({ dispatch }, { lfdnr, key }) {
      deleteContactPersonForAddress(lfdnr, key).then(() => {
        dispatch('loadContacts', { lfdnr })
      })
    },
    getFunktionen({ commit }) {
      getFunctions().then((response) => {
        commit('SET_FUNCTIONS', response)
      })
    },
    getDepartments({ commit }) {
      getDepartments().then((response) => {
        commit('SET_DEPARTMENTS', response)
      })
    },
  },
}
