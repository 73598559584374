import axios from 'axios'

export async function getSearchResults(URL, query, controller) {
  const response = await axios.get(URL, {
    params: { q: query },
    signal: controller.signal,
  })

  return response.data
}
