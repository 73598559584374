import {
  getDetailedArticleSaleChart,
  getArticleSaleChart,
} from '@/services/article/statisticService.js'

export default {
  state: {
    OtherStatisticsConf: {},
    showOtherStatistics: false,
    showDataTables: false,
    SaleChartTotalRevenue: [0, 0, 0, 0],
    SaleChartDB: [0, 0, 0, 0],
    SaleChartDetailed: [],
  },
  mutations: {
    SET_ConfigStatistics(state, payload) {
      state.OtherStatisticsConf = payload
    },
    SET_ShowOtherStatistics(state, payload) {
      state.showOtherStatistics = payload
    },
    SET_ShowDataTables(state, payload) {
      state.showDataTables = payload
    },
    SET_SaleChartDetailed(state, payload) {
      payload.forEach(function (item) {
        let temp = item.date.split('T')
        item.date = temp[0]
      })
      payload.sort(function (a, b) {
        let dateA = new Date(a)
        let dateB = new Date(b)
        if (dateA > dateB) {
          return 1
        }
        if (dateA < dateB) {
          return -1
        }
      })
      state.SaleChartDetailed = payload
    },
    SET_SaleChart(state, payload) {
      state.SaleChartDB = []
      state.SaleChartTotalRevenue = []
      for (let item in payload) {
        state.SaleChartTotalRevenue.push(payload[item].totalRevenue)
        state.SaleChartDB.push(payload[item].db)
      }
    },
  },
  actions: {
    async SET_ShowStatistics({ dispatch, commit }, payload) {
      commit('SET_ConfigStatistics', payload)
      dispatch('GET_SaleChartDetailed')
    },
    async GET_SaleChartDetailed({ commit, state }) {
      let config = state.OtherStatisticsConf
      getDetailedArticleSaleChart(
        config.artNr,
        config.timeFilter.fromDate,
        config.timeFilter.toDate,
        config.pointIndex
      ).then((chart) => {
        commit('SET_SaleChartDetailed', chart)
        commit('SET_ShowOtherStatistics', true)
      })
    },
    async GET_SaleChart({ commit }, payload) {
      commit(
        'SET_SaleChart',
        await getArticleSaleChart(
          payload.artNr,
          payload.fromDate,
          payload.toDate
        )
      )
    },
  },

  getters: {
    SaleChartDetailedDates(state) {
      let Dates = []
      state.SaleChartDetailed.forEach((item) => {
        Dates.push(item.date)
      })
      return Dates
    },
    SaleChartDetailedRevenue(state) {
      let Numbers = []
      state.SaleChartDetailed.forEach((item) => {
        Numbers.push(item.revenue)
      })
      return Numbers
    },
    SaleChartDetailedDB(state) {
      let Numbers = []
      state.SaleChartDetailed.forEach((item) => {
        Numbers.push(item.db)
      })
      return Numbers
    },
  },
}
