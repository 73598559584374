import {
  getArticleSaleDataSM,
  getArticleSaleDataPerAddress,
  getArticleSaleDataSMPerAddress,
} from '@/services/article/statisticService.js'

export default {
  state: {
    OtherStatisticsConf: {},
    DataPerSM: null,
    DataPerAddress: null,
    DataPerAddressForSM: null,
    showSaleDataPerAddressForSM: false,
    smCharacteristic: null,
  },
  mutations: {
    SET_ConfigStatisticsSM(state, payload) {
      state.OtherStatisticsConf = payload
    },
    SET_DATAPERSM(state, DataPerSM) {
      state.DataPerSM = DataPerSM
    },
    SET_DATAPERADDRESS(state, DataPerAddress) {
      state.DataPerAddress = DataPerAddress
    },
    SET_SHOWSALEDATAPERADDRESSFORSM(state, payload) {
      state.showSaleDataPerAddressForSM = payload
    },
    SET_SMCHARACTERISTIC(state, payload) {
      state.smCharacteristic = payload
    },
    SET_DATAPERADDRESSFORSM(state, payload) {
      state.DataPerAddressForSM = payload
    },
  },
  actions: {
    async SET_ShowDataPerAddress({ dispatch, commit }, payload) {
      commit('SET_ConfigStatisticsSM', payload)
      dispatch('loadDataPerAddress')
    },
    //ladet Artikeltypen für Combobox
    async loadDataPerAddress({ commit, state }) {
      let config = state.OtherStatisticsConf
      commit(
        'SET_DATAPERADDRESS',
        await getArticleSaleDataPerAddress(
          config.artNr,
          config.timeFilter.fromDate,
          config.timeFilter.toDate,
          config.pointIndex
        )
      )
    },
    async SET_ShowDataPerSM({ dispatch, commit }, payload) {
      commit('SET_ConfigStatisticsSM', payload)
      dispatch('loadDataPerSM')
    },
    //ladet Artikeltypen für Combobox
    async loadDataPerSM({ commit, state }) {
      let config = state.OtherStatisticsConf
      commit(
        'SET_DATAPERSM',
        await getArticleSaleDataSM(
          config.artNr,
          config.timeFilter.fromDate,
          config.timeFilter.toDate,
          config.pointIndex
        )
      )
    },
    async showSaleDataPerAddressForSM({ commit }, payload) {
      commit('SET_SHOWSALEDATAPERADDRESSFORSM', payload)
    },
    async SET_ShowDataPerAddressForSM({ dispatch, commit }, payload) {
      commit('SET_SMCHARACTERISTIC', payload)
      dispatch('loadDataPerAddressForSM')
    },
    async loadDataPerAddressForSM({ commit, state }) {
      let config = state.OtherStatisticsConf
      commit(
        'SET_DATAPERADDRESSFORSM',
        await getArticleSaleDataSMPerAddress(
          config.artNr,
          state.smCharacteristic,
          config.timeFilter.fromDate,
          config.timeFilter.toDate,
          config.pointIndex
        )
      )
    },
  },
  getters: {
    DataPerSM(state) {
      return state.DataPerSM
    },
    DataPerAddressForSM(state) {
      return state.DataPerAddressForSM
    },
  },
}
