export default {
  state: {
    showAmount: true,
  },
  mutations: {
    SET_SHOW_AMOUNT(state, showAmount) {
      state.showAmount = showAmount
    },
  },
  actions: {
    setShowAmount({ commit }, showAmount) {
      commit('SET_SHOW_AMOUNT', showAmount)
    },
  },
}
